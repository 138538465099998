import classNames from 'classnames'
import React from 'react'
import s from './calendar-cell.scss'
import {DayEvents} from './day-events'
import {CalendarCellProps, CalendarCellState} from '.'

export class CalendarCell extends React.Component<CalendarCellProps, CalendarCellState> {
  state = {
    hover: false,
  }

  registerContainerRef = ref => {
    const {registerContainerRef, week, weekDay} = this.props
    registerContainerRef(week, weekDay, ref)
  }

  openPopup = (timestamp: string, events: wix.events.Event[]) => {
    if (events.length === 1) {
      this.props.openMonthlyCalendarEvent(events[0].id)
    } else {
      this.props.openMonthlyCalendarPopup(timestamp)
    }
  }

  handleMouseOver = () => {
    this.setState({hover: true})
  }

  handleMouseOut = () => {
    this.setState({hover: false})
  }

  handleCellClick = () => {
    const {timestamp, events, closeMonthlyCalendarPopup} = this.props

    events.length ? this.openPopup(timestamp, events) : closeMonthlyCalendarPopup()
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleCellClick()
    }
  }

  render() {
    const {
      selectionExists,
      active,
      borderStyle,
      otherMonthBackground,
      actionBackground,
      isPast,
      isToday,
      isCurrentMonth,
      events,
      dayNumber,
      responsiveCells,
    } = this.props

    const useActionBackground = events.length && (active || this.state.hover)

    const style = {
      ...((!isToday || selectionExists) && !active ? borderStyle : {}),
      ...((!isCurrentMonth || isPast) && !useActionBackground ? otherMonthBackground : {}),
      ...(useActionBackground ? actionBackground : {}),
    }

    return (
      <div
        className={classNames(s.cell, {
          [s.today]: isToday && !selectionExists,
          [s.withEvents]: events.length,
          [s.active]: active,
          [s.responsiveCells]: responsiveCells,
        })}
        style={style}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onClick={this.handleCellClick}
        onKeyPress={this.handleKeyPress}
        tabIndex={events.length ? 0 : null}
        data-hook={isCurrentMonth ? `calendar-day-${dayNumber}` : `calendar-other-month-day-${dayNumber}`}
      >
        <div ref={this.registerContainerRef} className={s.content}>
          <div
            className={classNames(s.day, {[s.otherMonth]: !isCurrentMonth, [s.today]: isToday})}
            data-hook={isCurrentMonth ? `day-of-month-${dayNumber}` : `day-of-other-month-${dayNumber}`}
          >
            {dayNumber}
          </div>
          {events.length ? <DayEvents events={events} /> : null}
        </div>
        <div className={s.border} />
      </div>
    )
  }
}
