import {ExperimentsBag} from '@wix/yoshi-flow-editor'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {CommonState} from '../types/state'

const isExperimentEnabled = (experiments: ExperimentsBag, name: string) => experiments[name] === 'true'

export const isCalendarResponsiveCells = (state: CommonState) =>
  isExperimentEnabled(state.experiments, ExperimentNames.CalendarResponsiveCells)

export const isHeadlessPaymentRedirectUrlEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, ExperimentNames.HeadlessPaymentRedirectUrl)
